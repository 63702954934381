<template>
  <vxe-grid
    resizable
    autoResize
    ref="vxeTable"
    :border="tableBorder"
    :size="size"
    :height="height"
    :max-height="maxHeight"
    :data="tableData"
    :row-id="rowKey"
    showOverflow
    align="center"
    highlight-current-row
    highlight-hover-row
    :loading="loading"
    highlight-current-column
    highlight-hover-column
    :scroll-y="scrollY"
    :columns="columnList"
    :seq-config="seqConfig"
    :row-class-name="getRowClassName"
    @current-change="handleRowCurrentChange"
    @sort-change="handleSortChanged"
    @toolbar-button-click="handleToolbarButtonClick"
    @checkbox-change="handleCheckboxChange"
    @checkbox-all="handleCheckboxAll"
    :checkbox-config="checkboxConfig"
    :radio-config="radioConfig"
    :toolbarConfig="toolbarConfig"
    @radio-change="({row})=>$emit('radio-change', row)"
    :customConfig="customConfig"
    :sort-config="defaultSort"
    :expand-config="expandConfig"
    :tooltip-config="tooltipConfig"
    style="width: 100%"
  >
    <vxe-column type="expand" width="80" :content-render="{name: 'MyExpand'}"></vxe-column>
    <template #pager>
      <vxe-pager
        :size="size"
        v-if="showPager && pagination && pagination.total"
        :current-page="pagination.currentPage"
        @page-change="handleCurrentChanged"
        :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="pagination.pageSize"
        :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total'
        ]"
        :total="pagination.total"
      >
      </vxe-pager>
    </template>
  </vxe-grid>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'expand-table',
  props: {
    type: {
      type: String,
      default: 'saleContract',
    },
    loading: {
      type: Boolean,
      default () {
        return false;
      },
    },
    pagination: {
      type: Object,
      default () {
        return {};
      },
    },
    tableData: {
      type: Array,
      default () {
        return [];
      },
    },
    sortInfo: {
      type: Object,
      validator: function (value) {
        if (value) {
          // key必须包含orderByProperty和orderByType
          return 'orderByProperty' in value && 'orderByType' in value;
        }
        return true;
      },
    },
    rowKey: {
      type: String,
      default: 'id',
    },
    height: {
      type: [Number, String],
    },
    maxHeight: {
      type: [Number, String],
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    showPager: {
      type: Boolean,
      default: true,
    },
    scrollY: {
      type: Object,
      default: () => ({
        enabled: true,
        mode: 'default',
        gt: 30,
      }),
    },
    leftToolbar: {
      type: Array,
      default: () => [],
    },
    showRightToolbar: {
      type: Boolean,
      default: false,
    },
    getRowClass: {
      type: Function,
      default: null,
    },
    tooltipConfig: {
      type: Object,
      default: () => {},
    },
    expandConfig: {
      type: Object,
      default: () => {},
    },
    columnList: {
      type: Array,
      default: () => [],
    },
    checkboxConfig: {
      type: Object,
    },
    radioConfig: {
      type: Object,
    },
    tableBorder: {
      type: [String, Boolean],
      default: 'outer',
    },
  },
  data () {
    return {
      customConfig: {
        storage: false,
        checkMethod: ({ column }) => {
          return !column.params || !column.params.isActionColumn;
        },
      },
    };
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
    toolbarConfig () {
      let buttons = this.leftToolbar.filter(
        t => !t.permission || this.actionPermissionMap[t.permission],
      );
      if (buttons.length > 0 || this.showRightToolbar) {
        return {
          import: false,
          export: false,
          zoom: true,
          custom: true,
          slots: {
            buttons: ({ x }, h) => {
              console.log(x);
              let custom = this.leftToolbar
                .filter(t => t.type === 'custom')
                .map(t => t.render(h));
              return buttons
                .filter(t => t.type !== 'custom')
                .map(t =>
                  h(
                    'el-button',
                    {
                      props: {
                        type: t.status,
                        size: t.size,
                        icon: t.icon,
                        disabled: t.disabled || false,
                      },
                      on: {
                        click: t.click,
                      },
                    },
                    t.name,
                  ),
                )
                .concat(custom);
            },
          },
        };
      } else {
        return null;
      }
    },
    defaultSort () {
      if (this.sortInfo) {
        return {
          trigger: 'cell',
          defaultSort: {
            field: this.sortInfo.orderByProperty,
            order:
              this.sortInfo.orderByType
              && this.sortInfo.orderByType.toUpperCase() === 'DESC'
                ? 'desc'
                : 'asc',
          },
          orders: ['desc', 'asc', null],
          remote: true,
        };
      } else {
        return {};
      }
    },
    seqConfig () {
      return {
        startIndex: 0,
        seqMethod: ({ row, rowIndex, column, columnIndex }) => {
          console.log(row, column, columnIndex);
          if (
            this.showPager
            && this.pagination
            && Object.keys(this.pagination).length > 0
          ) {
            return (
              (this.pagination.currentPage - 1) * this.pagination.pageSize
              + rowIndex
              + 1
            );
          } else {
            return rowIndex + 1;
          }
        },
      };
    },
  },
  methods: {
    handleToolbarButtonClick ({ code, button, $event }) {
      if (this.leftToolbar) {
        let btnConfig = this.leftToolbar.find(t => t.code === code);
        btnConfig.click && btnConfig.click();
      }
      this.$emit('toolbar-button-click', code, button, $event);
    },
    getRowClassName ({ row, rowIndex }) {
      if (this.getRowClass) {
        return this.getRowClass(row, rowIndex);
      }
    },
    handleSortChanged ({ order, property }) {
      if (order) {
        order = order.indexOf('asc') >= 0 ? 'ASC' : 'DESC';
      } else {
        property = null;
      }
      this.$emit('update:sortInfo', {
        orderByProperty: property,
        orderByType: order,
      });
      this.$emit('sort-change', property, order);
    },
    handleCurrentChanged ({ type, currentPage, pageSize, $event }) {
      console.log(type, $event);
      let { total } = this.pagination;
      this.$emit('update:pagination', { total, pageSize, currentPage });
      this.$emit('current-change', { pageSize, currentPage });
    },
    getColumnFormatter (column) {
      let emptyPlaceholder = ({ cellValue }) => {
        return cellValue === null || cellValue === undefined || cellValue === ''
          ? '--'
          : cellValue;
      };
      if (column.format) {
        return ({ cellValue }) => {
          let formatValue = column.format(cellValue);
          return emptyPlaceholder({ cellValue: formatValue });
        };
      } else {
        return emptyPlaceholder;
      }
    },
    handleRowCurrentChange ({ row, rowIndex }) {
      this.$emit('handleRowCurrentChange', row, rowIndex);
    },
    handleCheckboxChange ({checked, row}) {
      this.$emit('handleCheckboxChange', checked, row);
      this.handleSelectionChange();
    },
    handleCheckboxAll ({checked}) {
      this.$emit('handleCheckboxAll', checked);
      this.handleSelectionChange();
    },
    handleSelectionChange () {
      let selections = this.$refs.vxeTable.getCheckboxRecords();
      if (this.checkboxConfig && this.checkboxConfig.reserve) {
        selections = selections.concat(this.$refs.vxeTable.getCheckboxReserveRecords());
      }
      this.$emit('selection-change', selections);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.vxe-grid--pager-wrapper {
  margin-top: 16px;
}

/deep/ .vxe-buttons--wrapper {
  & > *:not(:first-child) {
    margin-left: 8px;
  }
}
/deep/ .el-button i + span {
  margin-left: 0.5em;
}
/deep/ .vxe-cell .table-action {
  cursor: pointer;
  color: #5195eb;
  &.table-action-delete{
    color: #F56C6C;
  }
}
/deep/ .vxe-cell .table-action + .el-dropdown {
  margin-left: 10px;
}
/deep/ .vxe-cell .table-action:not(:first-child) {
  margin-left: 10px;
}
/deep/.vxe-table--render-default .vxe-body--expanded-column.col--ellipsis>.vxe-body--expanded-cell{
  padding:10px 140px 10px 50px;
  .vxe-table--render-default.size--small{
    font-size: 12px;
  }
}
/deep/.vxe-table--render-default.border--outer .vxe-table--header-wrapper{
  background: #F2F4F7;
}
</style>
