import { mapGetters } from 'vuex';

const templateMixin = {
  computed: {
    ...mapGetters(['directlySaleCompanyIds']),
    isDirectlySalesCompany () {
      return this.directlySaleCompanyIds.includes(this.userInfo.tenantId);
    },
  },
};

export { templateMixin };

export default {
  computed: {
    ...mapGetters(['directlySaleCompanyIds']),
    isDirectlySalesCompany () {
      return this.directlySaleCompanyIds.includes(this.userInfo.tenantId);
    },
  },
  data () {
    return {
      userInfo: {},
    };
  },
  created () {
    this.userInfo = JSON.parse(this.$local.get('userInfo'));
  },
};
