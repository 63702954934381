<template>
  <div class="list-wrapper">
    <div class="content-wrapper">
      <div class="h-full">
        <XzTagGroup :data="statusWithAll" :active="activeStatus" @handleClick="handleStatusChange">
          <span class="title">订单状态</span>
        </XzTagGroup>
        <div class="table-wrapper" :class="{ 'fullscreen': isFullScreen }">
          <div class="top">
            <div class="left"></div>
            <div class="right">
              <a-radio-group v-if="!isDirectlySalesCompany" v-model="saleOrderType" buttonStyle="solid"
                @change="handleRadioChange">
                <a-radio-button v-if="isJoin" :class="{ 'active-radio': saleOrderType === 'saleOrder' }"
                  value="saleOrder">
                  加盟销售
                </a-radio-button>
                <a-radio-button :class="{ 'active-radio': saleOrderType === 'purchaseOrder' }" value="purchaseOrder">
                  直营销售
                </a-radio-button>
              </a-radio-group>
              <el-input v-model='form.code' style="width:200px;margin-left:10px;height:32px;line-height:32px;"
                @change="handleCodeSearch" placeholder="请输入订单编号" suffix-icon="el-icon-search"></el-input>
              <div style="padding-top: 2px; display: inline-block;margin-right: 2px;">
                <el-button @click="handleDrawer" style="margin-left:16px;width:32px;height:32px;padding:8px;">
                  <img :src="require('@/assets/adSearch.svg')" style='width:16px;height:16px;font-size:16px;' />
                </el-button>
                <el-button @click='handleFullScreen' style="width:32px;height:32px;padding:8px;">
                  <img :src="require('@/assets/ic_zhankai.svg')" v-if="isFullScreen"
                    style='width:16px;height:16px;font-size:16px;' />
                  <img :src="require('@/assets/ic_shouqi.svg')" v-else style='width:16px;height:16px;font-size:16px;' />
                </el-button>
                <el-button @click="handleReset" style="width:32px;height:32px;padding:8px;">
                  <img :src="require('@/assets/ic_refresh.svg')" style='width:16px;height:16px;font-size:16px;' />
                </el-button>
              </div>

              <el-popover placement="left" width="300" v-model="showAttribute" trigger="manual">
                <div class="top"><span>字段设置</span><time @click="showAttribute = false">&times;</time></div>
                <draggable v-model="lockLists" draggable=".list-item">
                  <template v-if="lockLists && lockLists.length">
                    <li v-for="(item, index) in lockLists" :key="item.fieldName" class="list-item">
                      <el-checkbox v-model="item.isShow" :disabled="item.isDisabled"
                        style="margin-top:6px;"></el-checkbox>
                      <div class="labelContainer">{{ item.fieldName }}</div>
                      <i :class="{ 'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular }"
                        style="font-size: 12px;margin-top:10px;margin-left:12px;"
                        @click="handleClickLock(item, index)"></i>
                      <i class="el-icon-s-unfold" style="font-size: 12px;margin-top:10px;margin-left:12px;"></i>
                    </li>
                  </template>
                  <template v-else>
                    <li class="noFixedItem">
                      锁定下列项目到此固定
                    </li>
                  </template>
                </draggable>
                <h3 style="border-top: 1px solid #DFE2E8;font-size:14px;height:40px;line-height:40px;margin-bottom:0;">
                  不固定</h3>
                <draggable v-model="attrLists" draggable=".list-item">
                  <li v-for="(item, index) in attrLists" :key="item.fieldName" class="list-item">
                    <el-checkbox v-model="item.isShow" :disabled="item.isDisabled"
                      style="margin-top:6px;"></el-checkbox>
                    <div class="labelContainer">{{ item.fieldName }}</div>
                    <i :class="{ 'el-icon-unlock': !item.isRegular, 'el-icon-lock': item.isRegular }"
                      style="font-size: 12px;margin-top:10px;margin-left:12px;"
                      @click="handleClickLock(item, index)"></i>
                    <i class="el-icon-s-unfold" style="font-size: 12px;margin-top:10px;margin-left:12px;"></i>
                  </li>
                </draggable>
                <div class="text-right">
                  <el-button type="default" size="mini" @click="handleResetCancel">重置</el-button>
                  <el-button type="primary" size="mini" @click="handleSave">保存</el-button>
                </div>
                <el-button slot="reference" @click='handleSort'
                  style="width:32px;height:32px;margin-left:8px;padding: 8px;">
                  <img :src="require('@/assets/ic_setting.svg')" style='width:16px;height:16px;' />
                </el-button>
              </el-popover>
            </div>
          </div>
          <ExpandTable :columnList="columnsLists" :pagination.sync="pagination" :showPager="false"
            :expandConfig="expandConfig" :tableData='data' tableBorder="outer">
          </ExpandTable>
        </div>
      </div>
    </div>
    <div class="pagination-wrapper" v-if="pagination && pagination.total">
      <vxe-pager size="mini" :current-page="pagination.currentPage" @page-change="handleNumChange"
        :page-sizes="[10, 20, 50, 100, 200]" :page-size="pagination.pageSize" :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total'
        ]" :total="pagination.total">
      </vxe-pager>
    </div>
    <a-modal v-model="visible" title="销售订单收款" :width="900" :bodyStyle="{ padding: '0 12px' }" centered @ok="handleOk"
      @cancel="visible = false;">
      <br />
      <a-descriptions bordered size="small">
        <a-descriptions-item label="核算单位">
          {{ orderInfo.accountingUnit }}
        </a-descriptions-item>
        <a-descriptions-item label="客户抬头">
          {{ orderInfo.customerHead }}
        </a-descriptions-item>
        <a-descriptions-item label="待收金额">
          {{ format(orderInfo.waitReceiptAmount) }}
        </a-descriptions-item>
      </a-descriptions>
      <a-alert v-if="isAlert" style="margin-top: 8px;" :message="warningMessage" banner closable
        @close="isAlert = false;" />
      <a-form-model ref="collection" :model="collectingForm">
        <xz-table ref="table" :columns="collectionColumns" :data="collectingForm.collectionData"
          :scroll="{ x: '100%', y: 600 }" rowKey="id">
          <span slot="amount" slot-scope="text, record, index">
            <a-form-model-item :prop="'collectionData.' + index + '.amount'" :rules="setRule(record)"
              style="margin-bottom: 0;">
              <a-input-number v-model="record.amount" placeholder="请输入结算金额" :min="0" :step="0.01" style="width: 100%;"
                :precision="2" />
            </a-form-model-item>
          </span>
          <template slot="footer" slot-scope="currentPageData">
            <div>
              <b>含税总金额（元）：</b>{{ Math.round(currentPageData.reduce((pre, cur) => pre + cur.amount, 0) * 100) /
          100 || '' }}
            </div>
          </template>
        </xz-table>
      </a-form-model>
    </a-modal>
    <a-drawer title="高级筛选" closable :width='460' :drawerStyle="drawerStyle" :bodyStyle="bodyStyle"
      :visible="drawerVisible" :maskClosable="true" @close='handleDrawer' class="advanced-search-drawer">
      <div style="flex: 1; overflow: auto" class="advanced-search">
        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="订单编号">
            <a-input v-model="form.code" placeholder="请输入订单编号" allow-clear />
          </a-form-model-item>
          <a-form-model-item label="订单状态">
            <a-select v-model="form.status" placeholder="请选择订单状态" allow-clear>
              <a-select-option v-for="opt in statusWithAll" :key="opt.value" :value="opt.value">
                {{ opt.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="isDirectlySalesCompany" label="交付状态">
            <a-select v-model="form.status" placeholder="请选择交付状态" allow-clear>
              <a-select-option v-for="opt in receiveStatusWithAll" :key="opt.value" :value="opt.value">
                {{ opt.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="订单金额">
            <a-input-number v-model="form.minOrderTotalAmount" placeholder="最小金额" :min="0" :step="0.01"
              style="width: 47%;" :precision="2" />
            ~
            <a-input-number v-model="form.maxOrderTotalAmount" placeholder="最大金额" :min="0" :step="0.01"
              style="width: 47%;" :precision="2" />
          </a-form-model-item>
          <a-form-model-item label="收款金额">
            <a-input-number v-model="form.minSellerAmount" placeholder="最小金额" :min="0" :step="0.01" style="width: 47%;"
              :precision="2" />
            ~
            <a-input-number v-model="form.maxSellerAmount" placeholder="最大金额" :min="0" :step="0.01" style="width: 47%;"
              :precision="2" />
          </a-form-model-item>
          <a-form-model-item label="合同编号" allow-clear>
            <a-input v-model="form.contractCode" placeholder="请输入合同编号" allow-clear />
          </a-form-model-item>
          <a-form-model-item label="最终用户">
            <a-input v-model="form.finalCustomerName" placeholder="请输入最终用户" allow-clear />
          </a-form-model-item>
          <a-form-model-item label="创建人">
            <a-input v-model="form.createUserName" placeholder="请输入创建人" allow-clear />
          </a-form-model-item>
          <a-form-model-item label="创建时间">
            <a-range-picker v-model="form.createDateRange" valueFormat="x" :placeholder="['开始时间', '结束时间']"
              style="width: 100%;" show-time allow-clear>
            </a-range-picker>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div style="text-align: right; height: 68px; line-height: 68px">
        <a-button @click="handleReset">
          重置
        </a-button>
        <a-button type="primary" style="margin-left: 10px;" @click="handleConfirmSearch">
          确定
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { saleOrderList, purchaseOrderList, cancelOrder, deleteSaleAndPurchOrder, tenantBaseInfo, receiptMoneyInfo, contactClaimList, saleOrderReceive, getFiledRuleLists, addFiledRule } from '@/api/contract';
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import XzTagGroup from '@/components/xiaozi-ui/xz-tag-group';
import * as Moment from 'dayjs';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
// import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import ExpandTable from '@/components/common/ExpandTable';
import { setOrderTableColumns } from './config/index';
import directlySaleCompanyMixin from '../mixins/directlySaleCompanyMixin';

const SALE_CONTRACT_STATUS_ALL = '';
const SALE_CONTRACT_STATUS_DRAFT = 0; // 草稿
const SALE_CONTRACT_STATUS_IN_PROGRESS = 3; // 执行中
const SALE_CONTRACT_STATUS_FINISHED = 5; // 已完结
const SALE_CONTRACT_STATUS_CANCELED = 4; // 已取消

const WAIT_TO_RECEIVE = 0;
const RECEIVED = 1;

const STATUS = [
  {
    label: '草稿',
    value: SALE_CONTRACT_STATUS_DRAFT,
  },
  {
    label: '执行中',
    value: SALE_CONTRACT_STATUS_IN_PROGRESS,
  },
  {
    label: '已完结',
    value: SALE_CONTRACT_STATUS_FINISHED,
  },
  {
    label: '已取消',
    value: SALE_CONTRACT_STATUS_CANCELED,
  },
];

const EXECUTION_STATUS_ARRAY_MAP = ['草稿', '审核中', '已驳回', '执行中', '已取消', '已完结'];

const COMMON_COLUMNS = [
  {
    title: '订单编号',
    field: 'code',
    key: 'code',
    width: 160,
    showOverflow: true,
  },
  {
    title: '订单状态',
    field: 'status',
    key: 'status',
    width: 160,
    scopedSlots: { customRender: 'status' },
    showOverflow: true,
  },
  {
    title: '订单金额',
    field: 'total',
    key: 'total',
    width: 160,
    showOverflow: true,
    customRender: (text) => {
      return text === '' || text === null ? '--' : text.toLocaleString();
    },
  },
  {
    title: '开票金额',
    field: 'invoiceAmount',
    key: 'invoiceAmount',
    width: 160,
    showOverflow: true,
    customRender: (text) => {
      return text === '' || text === null ? '--' : text.toLocaleString();
    },
  },
  {
    title: '收款金额',
    field: 'collectionAmount',
    key: 'collectionAmount',
    width: 160,
    showOverflow: true,
    customRender: (text) => {
      return text === '' || text === null ? '--' : text.toLocaleString();
    },
  },
  {
    title: '合同编号',
    field: 'contractCode',
    key: 'contractCode',
    width: 160,
    showOverflow: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '最终用户',
    field: 'finalCustomerName',
    key: 'finalCustomerName',
    showOverflow: true,
    width: 160,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '创建人',
    field: 'createUserName',
    key: 'createUserName',
    width: 160,
    showOverflow: true,
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '创建时间',
    field: 'createTime',
    key: 'createTime',
    showOverflow: true,
    width: 160,
    customRender: (text) => {
      return text ? this.$options.filters['date-format'](text) : '';
    },
  },
  {
    title: '客户抬头',
    field: 'customerHead',
    key: 'customerHead',
    width: 160,
    showOverflow: true,
  },
];

export default {
  name: 'SaleOrders',
  components: {
    xzTable,
    XzTagGroup,
    draggable,
    // TableWithPagination,
    ExpandTable,
  },
  mixins: [TablePaginationMixin, directlySaleCompanyMixin],
  computed: {
    ...mapGetters(['actionPermissionMap']),
    toolsAttrs () {
      return {
        refresh: true,
        zoom: true,
        fullScreen: this.isFullScreen,
        advancedSearch: true,
        custom: true,
      };
    },
    columnsLists () {
      let currentLists = setOrderTableColumns.call(this, this.saleOrderType, this.lockLists, this.attrLists);
      return currentLists;
    },
  },
  watch: {
    saleOrderType () {
      this.handleReset();
    },
  },
  data () {
    return {
      expandConfig: {
        accordion: true,
        lazy: false,
        expandAll: false,
        reserve: true,
      },
      drawerVisible: false,
      drawerStyle: {
        display: 'flex',
        flexDirection: 'column',
      },
      bodyStyle: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      },
      expandedRowRender: '',
      data: [],
      form: {
        code: undefined,
        status: undefined,
        minOrderTotalAmount: undefined,
        maxOrderTotalAmount: undefined,
        minSellerAmount: undefined,
        maxSellerAmount: undefined,
        contractCode: undefined,
        finalCustomerName: undefined,
        createUserName: undefined,
        createDateRange: undefined,
      },
      activeStatus: '',
      total: 0,
      searchAttrs: {
        show: true,
        placeholder: '请输入订单编号',
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      statusWithAll: [
        {
          label: '全部',
          value: SALE_CONTRACT_STATUS_ALL,
        },
        ...STATUS,
      ],
      receiveStatusWithAll: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '待交付',
          value: WAIT_TO_RECEIVE,
        },
        {
          label: '已交付',
          value: RECEIVED,
        },
      ],
      saleOrderType: 'saleOrder',
      EXECUTION_STATUS_ARRAY_MAP,
      SALE_CONTRACT_STATUS_DRAFT,
      SALE_CONTRACT_STATUS_IN_PROGRESS,
      isJoin: false,
      visible: false,
      collectionColumns: [
        {
          title: '收款单号',
          dataIndex: 'settlementCode',
          key: 'settlementCode',
          width: 160,
          ellipsis: true,
        },
        {
          title: '收付日期',
          dataIndex: 'settlementDate',
          key: 'settlementDate',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return text ? Moment(text * 1).format('YYYY-MM-DD') : '--';
          },
        },
        {
          title: '收款单金额(元)',
          dataIndex: 'settlementAmount',
          key: 'settlementAmount',
          width: 160,
          ellipsis: true,
        },
        {
          title: '收款单余额(元)',
          dataIndex: 'claimAmount',
          key: 'claimAmount	',
          width: 160,
          ellipsis: true,
        },
        {
          title: '本次结算金额(元)',
          dataIndex: 'amount',
          key: 'amount',
          width: 160,
          scopedSlots: { customRender: 'amount' },
        },
      ],
      orderInfo: {},
      collectingForm: {
        collectionData: [],
      },
      warningMessage: '',
      isAlert: false,
      scrollHeight: 600,
      tableBody: null,
      isFullScreen: false,
      showAttribute: false,
      attrLists: [],
      fixedLists: [],
      checkList: [],
      lockLists: [],
      checkLockLists: [],
      attrPropLists: [],
      lockPropLists: [],
    };
  },
  methods: {
    handleRadioChange () {
      this.getRuleLists();
      this.showAttribute = false;
    },
    newOrder () {
      this.$router.push({
        name: 'SALESMANAGEMENT_BILLINGORDER_NEW',
      });
    },
    mapStausName (status) {
      let name = '';
      if (this.saleOrderType === 'saleOrder') {
        name = EXECUTION_STATUS_ARRAY_MAP[status];
      } else {
        name = status === 2 ? EXECUTION_STATUS_ARRAY_MAP[status + 1] : EXECUTION_STATUS_ARRAY_MAP[status];
      }
      return name;
    },
    setPurchOrderSts (status) {
      let retSts = status;

      if (status === SALE_CONTRACT_STATUS_IN_PROGRESS) {
        retSts = 2; // 采购订单执行中状态值为2
      }

      return retSts;
    },
    format (v) {
      let ret = '--';

      if (v || v === 0) {
        ret = v.toFixed(2);
      }

      return ret;
    },
    validatePayment (rule, value, callback) {
      if ((!value && value !== 0) || (Number(value || '') > rule.claimAmount)) {
        this.warningMessage = '结算金额不能为空且结算金额不能大于收款单余额';
        this.isAlert = true;
        return callback(new Error(' '));
      }
      callback();
    },
    setRule (row) {
      return [
        { claimAmount: row.claimAmount, validator: this.validatePayment, trigger: 'blur' },
      ];
    },
    async handleCollection (record) {
      this.isAlert = false;

      let res = await receiptMoneyInfo({ id: record.id });

      this.orderInfo = {
        id: record.id,
        ...res.body,
      };

      const param = {
        contactsType: 'CUSTOMER',
        receiptPaymentTypeEnum: 'RECEIPT',
        payableId: this.orderInfo.customerId,
        payableName: this.orderInfo.customerHead,
        type: 1, // 付款认领对应采购付款接口
        accountingUnit: this.orderInfo.accountingUnit,
        accountingUnitId: this.orderInfo.accountingUnitId,
      };

      res = await contactClaimList(param);

      let waitReceiptAmount = Number(this.orderInfo.waitReceiptAmount || '');

      (res?.body || []).forEach(v => {
        v.amount = Math.min(v.claimAmount, waitReceiptAmount);

        if (v.claimAmount <= waitReceiptAmount) {
          waitReceiptAmount = waitReceiptAmount - v.claimAmount;
        } else {
          waitReceiptAmount = 0;
        }
      });

      this.collectingForm.collectionData = res?.body || [];

      this.record = record;

      this.visible = true;
    },
    handleOk () {
      this.$refs.collection.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        let summary = this.collectingForm.collectionData.reduce((curr, next) => {
          return curr + Number(next.amount || '');
        }, 0);

        if (!summary || summary > this.orderInfo.waitReceiptAmount) {
          this.isAlert = true;
          this.warningMessage = '总认领金额不能为0，也不能大于待收金额';

          return false;
        }

        const itemList = [];

        this.collectingForm.collectionData.forEach(v => {
          if (Number(v.amount || '')) {
            itemList.push({
              amount: v.amount,
              settlementCode: v.settlementCode,
              settlementId: v.id,
            });
          }
        });

        const param = {
          saleOrderId: this.orderInfo.id,
          itemList,
        };

        saleOrderReceive(param).then(() => {
          this.handleSearch();
          this.visible = false;
        });
      });
    },
    handleConfirmSearch () {
      this.pagination.currentPage = 1;
      this.handleSearch();
    },
    handleReset () {
      this.activeStatus = '';

      for (const key in this.form) {
        this.form[key] = key === 'status' ? '' : undefined;
      }

      this.pagination.currentPage = 1;
      this.pagination.pageSize = 10;

      this.handleSearch();
    },
    handleNumChange ({ currentPage, pageSize }) {
      this.pagination.currentPage = currentPage;
      this.pagination.pageSize = pageSize;
      this.handleSearch();
    },
    setValOrUndefined (v) {
      return (v === '' || v === null) ? undefined : v;
    },
    async handleSearch () {
      const api = this.saleOrderType === 'saleOrder' ? saleOrderList : purchaseOrderList;

      let params = {};

      this.activeStatus = this.form.status;

      if (this.saleOrderType === 'saleOrder') {
        params = {
          ...this.form,
          findInvoiceAmountFlag: true,
          findCollectionAmountFlag: true,
          findOrderGoodsFlag: true,
          findGoodsFlag: true,
          createTimeStart: this.form.createDateRange?.[0],
          createTimeEnd: this.form.createDateRange?.[1],
        };
      } else if (this.saleOrderType === 'purchaseOrder') {
        params = {
          orderCode: this.form.code,
          orderSts: this.setPurchOrderSts(this.form.status),
          minOrderTotalAmount: this.form.minOrderTotalAmount,
          maxOrderTotalAmount: this.form.maxOrderTotalAmount,
          minSellerAmount: this.form.minSellerAmount,
          maxSellerAmount: this.form.maxSellerAmount,
          pcCode: this.form.contractCode,
          finalCustomerName: this.form.finalCustomerName,
          createUserName: this.form.createUserName,
          beginCreateTime: this.form.createDateRange?.[0],
          endCreateTime: this.form.createDateRange?.[1],
          findDeviceFlag: true,
          findGoodsFlag: true,
        };
      }

      for (const k in params) {
        params[k] = this.setValOrUndefined(params[k]);
      }
      this.data = [];
      const { body } = await api({
        ...params,
        orderBy: [
          {
            orderByProperty: 'lastUpdateTime',
            orderByType: 'DESC',
          },
        ],
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      });

      if (this.saleOrderType === 'saleOrder') {
        this.data = (body?.list || []).map(i => ({
          ...i,
          statusName: this.mapStausName(i.status),
          taxRate: i.taxRate ? parseInt(i.taxRate * 100) + '%' : '--',
          createTime: Moment(i.createTime * 1).format('YYYY-MM-DD HH:mm:ss'),
        }));
      } else if (this.saleOrderType === 'purchaseOrder') {
        this.data = (body?.list || []).map(v => ({
          id: v.id,
          code: v.orderCode,
          statusName: this.mapStausName(v.orderSts),
          total: v.orderTotalAmount,
          invoiceAmount: v.invoiceAmount,
          collectionAmount: v.sellerAmount,
          contractCode: v.pcCode,
          finalCustomerName: v.finalCustomerName,
          createUserName: v.createUserName,
          createTime: Moment(v.createTime * 1).format('YYYY-MM-DD HH:mm:ss'),
          customerHead: v.unit,
          goodsList: v.goodsList,
        }));
      }

      this.pagination.total = body?.total;
      if (this.drawerVisible) {
        this.drawerVisible = false;
      }
    },
    handleView (record) {
      this.$router.push({
        name: this.saleOrderType === 'saleOrder' ? 'SALESMANAGEMENT_BILLINGORDER_SALE_DETAIL' : 'SALESMANAGEMENT_BILLINGORDER_PURCH_DETAIL',
        query: {
          id: this.$encode(record.id),
        },
      });
    },
    handleCancel (record) {
      this.$confirm('确认取消该订单吗？', '确认取消', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        cancelOrder({ id: record.id }).then(() => {
          this.handleSearch();
        });
      });
    },
    handleDelete (record) {
      this.$confirm('确认删除订单吗？', '确定删除', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteSaleAndPurchOrder({ saleOrderId: record.id }).then(() => {
          this.handleSearch();
        });
      });
    },
    handleEdit (record) {
      this.$router.push({
        name: 'SALESMANAGEMENT_BILLINGORDER_NEW',
        query: {
          id: this.$encode(record.id),
        },
      });
    },
    handleStatusChange (v) {
      this.activeStatus = v.value;
      this.form.status = v.value;

      this.pagination.currentPage = 1;

      this.handleSearch();
    },
    handleCodeSearch (v) {
      this.form.code = v;

      this.pagination.currentPage = 1;

      this.handleSearch();
    },
    async getTenantBaseInfo () {
      const { body } = await tenantBaseInfo();

      // 小紫联营服务入驻状态（入驻状态（1-未入驻，2-入驻中，3-已入驻，4-已过期）,directlySaleCompanyIds判断是否上海小紫和海南小紫时
      this.isJoin = body.enteringStatus * 1 === 3 || this.directlySaleCompanyIds.includes(body?.tenantId);

      if (!this.isJoin) {
        this.saleOrderType = 'purchaseOrder';
      }

      this.handleSearch();
    },
    setAntTableBodyHeight () {
      const tableWrapper = document.querySelector('.xz-table-wrapper');
      const slotWrapper = document.querySelector('.slot-wrapper');
      const thead = document.querySelector('.ant-table-thead');

      this.tableBody = document.querySelector('.ant-table-body');

      this.scrollHeight = tableWrapper.offsetHeight - slotWrapper.offsetHeight - thead.offsetHeight - 100;

      this.getTenantBaseInfo();
    },
    handleClickLock (item, index) {
      if (item.isRegular) {
        item.isRegular = false;
        this.lockLists.splice(index, 1);
        this.attrLists.push(item);
      } else {
        item.isRegular = true;
        this.attrLists.splice(index, 1);
        this.lockLists.push(item);
      }
    },
    getRuleLists () {
      let businessType = {
        'saleOrder': '3',
        'purchaseOrder': '4',
      }[this.saleOrderType];
      getFiledRuleLists({
        businessType,
      }).then(({ body }) => {
        let currentLists = body || [];

        currentLists = JSON.parse(JSON.stringify(currentLists));
        currentLists = currentLists.map(i => ({
          ...i,
          isDisabled: ['订单编号', '订单状态', '订单金额'].includes(i.fieldName),
        }));

        const listMap = {};
        currentLists.forEach(i => {
          listMap[i.fieldKey] = i;
        });

        let columnLists = this.isDirectlySalesCompany ? [...COMMON_COLUMNS, {
          title: '订单收货/交付状态',
          field: 'receiveStatus',
          key: 'receiveStatus',
          width: 160,
          showOverflow: true,
        }] : [...COMMON_COLUMNS];

        // 这里需要根据 columnLists 获取需要处理的数据的值
        const lockLists = currentLists.filter(i => i.isRegular);

        const attrLists = [];
        columnLists.filter((i) => !i.fixed).forEach((i, index) => {
          if (listMap[i.key] === undefined) {
            attrLists.push({
              fieldKey: i.key,
              fieldName: i.title,
              businessType,
              isRegular: false,
              isShow: true,
              isDisabled: ['订单编号', '订单状态', '订单金额'].includes(i.title),
              sort: lockLists.length + index + 1,
            });
          } else if (!listMap[i.key].isRegular) {
            attrLists.push(listMap[i.key]);
          }
        });

        this.lockLists = lockLists;
        this.attrLists = attrLists;

        // 这里需要拼接字段属性
        this.attrPropLists = JSON.parse(JSON.stringify(this.attrLists));
        this.lockPropLists = JSON.parse(JSON.stringify(this.lockLists));
      });
    },
    handleFullScreen () {
      this.isFullScreen = !this.isFullScreen;
    },
    handleSort () {
      this.attrLists = this.attrPropLists;
      this.lockLists = this.lockPropLists;
      this.showAttribute = true;
    },
    handleResetCancel () {
      let businessType = businessType = {
        'saleOrder': '3',
        'purchaseOrder': '4',
      }[this.saleOrderType];

      let columnLists = JSON.parse(JSON.stringify(this.isDirectlySalesCompany ? [...COMMON_COLUMNS, {
        title: '订单收货/交付状态',
        field: 'receiveStatus',
        key: 'receiveStatus',
        width: 160,
        showOverflow: true,
      }] : [...COMMON_COLUMNS]));
      let attrLists = columnLists.map((i, index) => ({
        fieldKey: i.key,
        fieldName: i.title,
        businessType,
        isRegular: false,
        isShow: true,
        sort: index + 1,
        isDisabled: ['订单编号', '订单状态', '订单金额'].includes(i.title),
      }));
      this.attrLists = attrLists;
      this.lockLists = [];
    },
    handleSave () {
      let lists = [
        ...this.lockLists,
        ...this.attrLists,
      ].map((i, index) => ({
        ...i,
        sort: index + 1,
      }));
      addFiledRule(lists).then(() => {
        // this.handleSearch();这里需要根据结果 调整展示
        this.getRuleLists(lists);
        this.showAttribute = false;
      });
    },
    handleDrawer () {
      this.drawerVisible = !this.drawerVisible;
    },
  },
  created () {
    this.getTenantBaseInfo();
  },
  mounted () {
    this.expandedRowRender = 'expandedRowRender';

    this.getRuleLists();
  },
};
</script>
<style scoped lang="scss">
@mixin tag_round() {
  padding: 4px 8px;
  border-radius: 4px;
}

.list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    flex: 1;
    overflow: hidden;
    padding: 0 0 16px;

    .h-full {
      height: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 14px;
        font-weight: 600;
        padding-right: 2em;
      }

      .table-wrapper {
        padding: 16px 12px;
        flex: 1;
        overflow: auto;

        .ant-radio-button-wrapper {
          border-width: 0;
          background-color: #F2F4F7;

          &.active-radio {
            border-color: #E2F3FE;
            background-color: #E2F3FE;
            color: #237FFA;
          }
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          width: 0;
        }

        .code {
          cursor: pointer;
          color: #237FFA;
        }

        .status-0,
        .status-4 {
          @include tag_round();
          color: #1F2733;
          background-color: #F2F4F7;
        }

        .status-1,
        .status-2,
        .status-3 {
          @include tag_round();
          background-color: #E2F3FE;
          color: #237FFA;
        }

        .status-5 {
          @include tag_round();
          background-color: #E5FEEB;
          color: #14CA64;
        }
      }
    }
  }

  .pagination-wrapper {
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.table-wrapper.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
  z-index: 100;
  background: #FFFFFF;
}

.text-right {
  text-align: right;
  border-top: 1px solid #dddddd;
  padding-top: 10px;
  margin-top: 6px;
}

.top {
  display: flex;
  padding: 6px 0;
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #DFE2E8;
  margin-bottom: 10px;
  font-family: PingFang SC;
  font-size: 16px;
  color: #1F2733 100%;
  letter-spacing: 0px;
  opacity: 1;

  span {
    flex: 1;
    height: 24px;
    line-height: 24px;
  }

  time {
    cursor: pointer;
  }
}

.list-item {
  display: flex;
  cursor: move;
  height: 40px;
  padding: 4px 8px;

  .el-checkbox__label {
    height: 32px;
    line-height: 32px;
  }

  .labelContainer {
    flex: 1;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
  }
}

.noFixedItem {
  font-family: PingFang SC;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0px;
  line-height: 0px;
  opacity: 1;
  height: 40px;
  line-height: 32px;
  padding: 4px 8px;
  list-style: none;
}

/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background: rgb(35, 127, 250);
  border-color: rgb(35, 127, 250);
  opacity: 0.4;
  color: #FFFFFF;
}

.ant-table-body {
  overflow: overlay !important;
  padding-right: 16px;
  padding-bottom: 16px;
}

.ant-table-placeholder {
  border-top: 0;
}

.ant-table-body tr:last-child td {
  border-bottom: 0;
}

.ant-table-tbody tr:last-child td {
  border-bottom: 0;
}

.top {
  display: flex;
  box-shadow: none;

  .right {
    flex: 1;
    text-align: right;
  }
}

/deep/.vxe-table--render-default .vxe-table--expanded {
  display: inline-block;
  width: 17px;
  height: 17px;
  color: inherit;
  line-height: 13px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  outline: none;
  transition: all .3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;

  &.is--active {
    border: 1px solid #5195eb;
    color: #5195eb;
  }

  .vxe-table--expand-btn.vxe-icon--arrow-right.rotate90::before {
    content: '-';
    transform: rotate(90deg);
    border: none;
    position: absolute;
    left: 9px;
    top: 5.5px;
    font-size: 12px;
    color: #5195eb;
    width: 6px;
    // border-top: 1px solid #5195eb;
    height: 1px;
  }

  .vxe-table--expand-btn.vxe-icon--arrow-right::before {
    content: '+';
    transform: rotate(0deg);
    border: none;
    position: absolute;
    left: 1px;
    top: -2px;
    font-size: 14px;
  }
}

.el-tag.el-tag--success {
  background-color: #E5FEEB;
  border-color: #e1f3d8;
  color: #14CA64;
}

.el-tag.el-tag--light {
  background-color: #E2F3FE;
  border-color: #e1f3d8;
  color: #237FFA;

  &.el-tag--info {
    background-color: #F2F4F7;
    border-color: #e1f3d8;
    color: #1F2733;
  }
}

/deep/.vxe-header--column .vxe-cell--title {
  font-family: PingFang SC;
  color: #1F2733;
  letter-spacing: 0px;
  opacity: 1;
}

/deep/ .vxe-table--render-default .vxe-cell .vxe-cell--label {
  font-family: PingFang SC;
  color: #1F2733;
  letter-spacing: 0px;
}

/deep/ .vxe-table--body-wrapper::-webkit-scrollbar {
  height: 12px;
  width: 10px;
}

/deep/ .vxe-table--body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 5px #C9CED6;
  background: #C9CED6;
}

//鼠标移入样式
/deep/ .vxe-table--body-wrapper::-webkit-scrollbar-thumb:hover {
  background: #C9CED6;
}

.advanced-search {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

/deep/.ant-drawer-body {
  padding: 16px;
}
</style>
