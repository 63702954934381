import * as Moment from 'dayjs';
function setExpandTbCols (saleOrderType, lockLists) {
  let childColumns = {
    saleOrder: [
      {
        title: '商品名称',
        field: 'goodsName',
        key: 'goodsName',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
      },
      {
        title: '规格',
        field: 'specification',
        key: 'specification',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
        slots: {
          default: ({ row }, h) => {
            return [h('div', row.specification || '-')];
          },
        },
      },
      {
        title: '型号',
        field: 'model',
        key: 'model',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
      },
      {
        title: '数量',
        field: 'num',
        key: 'num',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
        slots: {
          default: ({ row }, h) => {
            return [h('div', row.num.toLocaleString())];
          },
        },
      },
      {
        title: '单价',
        field: 'taxPrice',
        key: 'taxPrice',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
        slots: {
          default: ({ row }, h) => {
            return [h('div', row.taxPrice.toLocaleString())];
          },
        },
      },
      {
        title: '税率',
        field: 'taxRate',
        key: 'taxRate',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
        slots: {
          default: ({ row }, h) => {
            return [h('div', `${(row.taxRate * 100).toFixed(2)}%`)];
          },
        },
      },
    ],
    purchaseOrder: [
      {
        title: '商品名称',
        field: 'goodsName',
        key: 'goodsName',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
      },
      {
        title: '规格',
        field: 'specification',
        key: 'specification',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
        slots: {
          default: ({ row }, h) => {
            return [h('div', row.specification || '-')];
          },
        },
      },
      {
        title: '型号',
        field: 'model',
        key: 'model',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
      },
      {
        title: '数量',
        field: 'currentBillingNum',
        key: 'currentBillingNum',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
        slots: {
          default: ({ row }, h) => {
            return [h('div', row.currentBillingNum.toLocaleString())];
          },
        },
      },
      {
        title: '单价',
        field: 'unitPrice',
        key: 'unitPrice',
        width: 160,
        showOverflow: true,
        headAlign: 'left',
        align: 'left',
        slots: {
          default: ({ row }, h) => {
            return [h('div', row.unitPrice.toLocaleString())];
          },
        },
      },
      {
        title: '税率',
        field: 'taxRate',
        key: 'taxRate',
        width: 160,
        headAlign: 'left',
        align: 'left',
        showOverflow: true,
        customRender: (text) => {
          return text ? text : '--';
        },
      },
    ],
  };
  let fixedIdLists = lockLists.filter(i => i.isShow && i.isRegular).map(i => i.fieldKey);
  let fixedIdLockLen = fixedIdLists.length;
  let currentLists = childColumns[saleOrderType];
  currentLists = currentLists.map((i, index) => ({
    ...i,
    fixed: index < fixedIdLockLen ? 'left' : '',
    minWidth: index < fixedIdLockLen ? '' : i.width,
    width: index < fixedIdLockLen ? i.width : '',
  }));
  return currentLists;
}

export function setOrderTableColumns (saleOrderType, lockLists, attrLists) {
  let orderTableColumns = [
    {
      title: '订单编号',
      field: 'code',
      key: 'code',
      width: 160,
      showOverflow: true,
      headAlign: 'left',
      align: 'left',
      slots: {
        default: ({ row }, h) => {
          let queryStatus = this.$store.state.Auth.actionPermissionMap['order:list:detail'] || false;
          let actions = queryStatus ? [
            <span class='table-action' onClick={()=>this.handleView(row)}>{row.code}</span>,
          ] : [
            <span>{row.code}</span>,
          ];
          return [h('div', actions)];
        },
      },
    },
    {
      title: '订单状态',
      field: 'status',
      key: 'status',
      width: 160,
      headAlign: 'left',
      align: 'left',
      slots: {
        default: ({ row }, h) => {
          let actions = [];
          let type = row.statusName === '执行中' ? '' : row.statusName === '已完结' ? 'success' : 'info';
          actions = [
            <el-tag type={type}>{row.statusName}</el-tag>,
          ];
          return [h('div', actions)];
        },
      },
      showOverflow: true,
    },
    {
      title: '订单收货/交付状态',
      field: 'receiveStatus',
      key: 'receiveStatus',
      width: 160,
      headAlign: 'left',
      align: 'left',
      slots: {
        default: ({ row }, h) => {
          return [h('div', !row.receiveStatus ? '待交付' : '已交付')];
        },
      },
      showOverflow: true,
    },
    {
      title: '订单金额',
      field: 'total',
      key: 'total',
      width: 160,
      showOverflow: true,
      headAlign: 'left',
      align: 'left',
      slots: {
        default: ({ row }, h) => {
          return [h('div', row.total.toLocaleString())];
        },
      },
    },
    {
      title: '开票金额',
      field: 'invoiceAmount',
      key: 'invoiceAmount',
      width: 160,
      showOverflow: true,
      headAlign: 'left',
      align: 'left',
      slots: {
        default: ({ row }, h) => {
          return [h('div', row.invoiceAmount.toLocaleString())];
        },
      },
    },
    {
      title: '收款金额',
      field: 'collectionAmount',
      key: 'collectionAmount',
      width: 160,
      showOverflow: true,
      headAlign: 'left',
      align: 'left',
      slots: {
        default: ({ row }, h) => {
          return [h('div', row.collectionAmount.toLocaleString())];
        },
      },
    },
    {
      title: '合同编号',
      field: 'contractCode',
      key: 'contractCode',
      width: 160,
      headAlign: 'left',
      align: 'left',
      showOverflow: true,
      customRender: (text) => {
        return text ? text : '--';
      },
    },
    {
      title: '最终用户',
      field: 'finalCustomerName',
      key: 'finalCustomerName',
      showOverflow: true,
      width: 160,
      headAlign: 'left',
      align: 'left',
      customRender: (text) => {
        return text ? text : '--';
      },
    },
    {
      title: '创建人',
      field: 'createUserName',
      key: 'createUserName',
      width: 160,
      headAlign: 'left',
      align: 'left',
      showOverflow: true,
      customRender: (text) => {
        return text ? text : '--';
      },
    },
    {
      title: '创建时间',
      field: 'createTime',
      key: 'createTime',
      headAlign: 'left',
      align: 'left',
      showOverflow: true,
      width: 160,
      customRender: (text) => {
        return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '--';
      },
    },
    {
      title: '客户抬头',
      field: 'customerHead',
      key: 'customerHead',
      width: 160,
      headAlign: 'left',
      align: 'left',
      showOverflow: true,
    },
  ];
  let showIdLists = attrLists.filter(i => i.isShow).map(i => i.fieldKey);
  let fixedIdLists = lockLists.filter(i => i.isShow && i.isRegular).map(i => i.fieldKey);
  let allShowIdLists = [...fixedIdLists, ...showIdLists];

  orderTableColumns = orderTableColumns.filter(i => allShowIdLists.includes(i.field)).map(i => ({
    ...i,
    fixed: fixedIdLists.includes(i.field) ? 'left' : '',
    minWidth: fixedIdLists.includes(i.field) ? '' : i.width,
    width: fixedIdLists.includes(i.field) ? i.width : '',
  }));
  let tempLists = [];
  for (let i = 0; i < allShowIdLists.length; i++) {
    const info = orderTableColumns.find(j => j.field === allShowIdLists[i]);
    tempLists.push(info);
  }
  orderTableColumns = tempLists;
  orderTableColumns = [
    {
      type: 'expand',
      width: 50,
      title: '',
      fixed: lockLists && lockLists.length ? 'left' : '',
      slots: {
        content: ({ row }, h) => {
          return [h('vxe-grid', {
            style: {
              'z-index': 999,
            },
            props: {
              align: 'center',
              resizable: true,
              stripe: false,
              width: '80%',
              border: 'full',
              autoResize: true,
              showHeader: true,
              showOverflow: true,
              maxHeight: '500px',
              size: 'mini',
              data: row.goodsList || [],
              columns: setExpandTbCols(saleOrderType, lockLists),
            },
          })];
        },
      },
    },
    ...orderTableColumns,
  ];
  if (saleOrderType === 'saleOrder') {
    orderTableColumns = [
      ...orderTableColumns,
      {
        title: '操作',
        fixed: 'right',
        showOverflow: false,
        minWidth: '120px',
        headAlign: 'right',
        align: 'right',
        slots: {
          default: ({ row }, h) => {
            let editAuth = 'order:list:update';
            let cancelAuth = 'order:list:cancel';
            let deleteAuth = 'order:list:delete';
            let actions = [];
            let queryStatus = false;
            if (row.status === 0) {
              queryStatus = this.$store.state.Auth.actionPermissionMap[cancelAuth] || false;
              queryStatus && (actions = [
                <span class='table-action' onClick={()=>this.handleCancel(row)}>取消</span>,
              ]);
              queryStatus = this.$store.state.Auth.actionPermissionMap[editAuth] || false;
              queryStatus && actions.push(
                <span class='table-action' onClick={()=>this.handleEdit(row)}>编辑</span>,
              );
              queryStatus = this.$store.state.Auth.actionPermissionMap[deleteAuth] || false;
              queryStatus && actions.push(
                <span class='table-action table-action-delete' onClick={()=>this.handleDelete(row)}>删除</span>,
              );
            }
            if (row.status === 3 && row.collectionStatus !== 2) {
              queryStatus = this.$store.state.Auth.actionPermissionMap['order:list:receipt'] || false;
              queryStatus && actions.push(
                <span class='table-action' onClick={()=>this.handleCollection(row)}>收款</span>,
              );
            }
            return [h('div', actions)];
          },
        },
      },
    ];
  }
  return orderTableColumns;
}
